<template>
  <div class="h100 d-flex flex-column align-center justify-content-center">
    <img src="~@/assets/img/default/payment-error.svg" class="mb-7" />
    <div style="font-weight: 600;">
      Что-то пошло не так
    </div>
    <a href="#" @click.prevent="tryAgaint" class="ml-black-btn mt-8">
      <i class="mdi mdi-refresh"></i>
      Попробовать еще раз
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    tryAgaint() {
      this.$router.push({ name: 'Confirming' })
    }
  }
}
</script>
